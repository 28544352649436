<template>
    <!-- Activation page-->
    <div v-if="loading" class="misc-wrapper">
        <b-link class="brand-logo">
            <vuexy-logo />
            <!-- <h2 class="brand-text text-primary ml-1">
                Rentila
            </h2> -->
        </b-link>
    
        <div v-if="activated" class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
            <h1 class="mb-2 pl-2">
                {{ i18nT(`Le compte est maintenant `) }} <span class="text-primary">{{ i18nT(`actif`) }}</span>! ✨
            </h1>
            <p class="mb-2">
                {{ i18nT(`Cliquez sur le bouton ci-dessous pour visiter la page d'accueil`) }}.
            </p>

            <!-- image -->
            <b-img
                fluid
                :src="imgUrl"
                alt="Side img"
                class="my-2"
                :width="800"
            />

            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                :to="{name: 'dashboard-landlord'}"
            >
                {{ i18nT(`Ramène-moi à la maison`)}}
            </b-button>
    
            </div>
        </div>

        <div v-if="!activated" class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
            <h1 class="mb-2 pl-2">
                <RtIcon variant="light" icon="circle-xmark text-danger" />
                {{ i18nT(`Un problème est survenu lors de l'activation de votre compte`) }}
            </h1>
            <p class="mb-2">
                {{ i18nT(`Cliquez sur le bouton ci-dessous pour accéder à l'écran de connexion`) }}.
            </p>

            <!-- image -->
            <!-- <b-img
                fluid
                :src="downImg"
                alt="Error page"
                class="w-70"
            /> -->

            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                :to="{path:'/login'}"
            >
                {{ i18nT(`Allez vous connecter`)}}
            </b-button>
    
            </div>
        </div>
    </div>
  <!-- / Activation page-->
</template>
  
<script>
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@client/Logo.vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from "@/auth/useAuth";


export default {
components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
},
data() {
    return {
        activated: null,
        loading: false,
        downImg: require('@/assets/images/pages/error.svg'),
        sideImg: require('@/assets/images/pages/login-v2.svg'),
    }
},
computed: {
    DownImgUrl() {
    if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
    }
    return this.downImg
    },
    imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
            return this.sideImg
        }
        return this.sideImg
    }
},
created() {
    console.log(this.$route)
    const hash = this.$route.query.hash

    const formData = new FormData()

    formData.append('hash', hash)

    this.$http.post('activate', formData)
    .then((response) => {

        if(response.data.data.auth_key) {

            useAuth.setToken(response.data.data[`auth_key`])
            // useAuth.setCurWorkspace(response.data.data.current_workspace)
            // useAuth.setWorkspaces(response.data.data.menu.workspaces)
            // useAuth.setSoftwares(response.data.data.menu.apps)
            // useAuth.setCurrentSoftware(response.data.data.current_sotftware)
            useAuth.setProfile(response.data.data.user)
            useAuth.setUserType(response.data.data[`user_type`])
            //useAuth.setUserPermissions(response.data.data.menu.user.Permissions)

            localStorage.setItem(
                'userData',
                JSON.stringify(response.data.data.user)
            )
            //this.$store.commit('app/UPDATE_LOCAL_USER', response.data.data.menu.user)

            this.loading = true
            this.activated = true
        }
    })
    .catch((err) => {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
                title: this.errorMessage(err),
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: this.i18nT(`Please try again later.`)
            }
        })

        this.loading = true
        this.activated = false
    })
}
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
  